'use client';

import React from 'react';
import MobileHeader from '@/components/MobileHeader';
import Image from 'next/image';
import Link from 'next/link';
import { footerItems } from '@/constants/footer';

export default function MobileHomePage() {
  return (
    <main className="min-h-screen relative bg-[#0A0A0A]">
      {/* Anti-banding Noise Overlay */}
      <div 
        className="fixed inset-0 mix-blend-overlay opacity-[0.25] pointer-events-none z-10"
        style={{
          backgroundImage: `url("data:image/svg+xml,%3Csvg viewBox='0 0 2000 2000' xmlns='http://www.w3.org/2000/svg'%3E%3Cfilter id='noiseFilter'%3E%3CfeTurbulence type='fractalNoise' baseFrequency='0.5' numOctaves='5' stitchTiles='stitch'/%3E%3CfeColorMatrix type='saturate' values='0'/%3E%3C/filter%3E%3Crect width='100%25' height='100%25' filter='url(%23noiseFilter)'/%3E%3C/svg%3E")`,
          backgroundRepeat: 'repeat',
          transform: 'translateZ(0)',
          backfaceVisibility: 'hidden'
        }}
      />

      {/* Content */}
      <div className="relative z-20">
        <MobileHeader />

        {/* Hero Section */}
        <section className="relative bg-[#0a0a0a] min-h-[85vh] flex flex-col items-center">
          {/* Top fade effect */}
          <div className="fixed top-0 left-0 right-0 h-20 bg-gradient-to-b from-[#0a0a0a]/90 to-transparent z-40" />
          
          {/* Hero Text */}
          <div className="w-full text-center px-4 mt-[8vh]">
            <div className="flex flex-col text-center space-y-3">
              {/* Italian Audio Engineer */}
              <div className="font-medium text-[23px] text-white">
                <span>Italian </span>
                <span className="relative font-extralight italic text-static-gradient">
                  Audio Engineer
                </span>
              </div>
              
              {/* Helping Artists Unlock */}
              <div className="font-medium text-[23px] text-white">
                Helping <span className="relative font-extralight italic text-static-gradient">
                  Artists
                </span> Unlock
              </div>
              
              {/* World Class Sound */}
              <div className="font-medium text-[23px] text-white">
                World Class Sound,
              </div>
              
              {/* Globally */}
              <div className="font-medium text-[23px] text-white">
                Globally.
              </div>
              
              <div className="flex justify-center w-full">
                <Link 
                  href="https://open.spotify.com/playlist/5cFUYTfH0gHhYP8DsgptAU?si=d1f7d511b56e419a"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-[14px] mt-6 mb-4 inline-block group transition-all duration-300"
                >
                  <span className="flex items-center px-2.5 py-1 bg-[#0A0A0A] rounded-full hover:bg-[#111] transition-all duration-300 border border-[#1DB954]/30 hover:border-[#1DB954]/50">
                    <svg 
                      viewBox="0 0 24 24" 
                      className="w-3.5 h-3.5 mr-1 text-[#1DB954] group-hover:text-[#1ed760] transition-colors duration-300"
                      fill="currentColor"
                    >
                      <path d="M12 0C5.4 0 0 5.4 0 12s5.4 12 12 12 12-5.4 12-12S18.66 0 12 0zm5.521 17.34c-.24.359-.66.48-1.021.24-2.82-1.74-6.36-2.101-10.561-1.141-.418.122-.779-.179-.899-.539-.12-.421.18-.78.54-.9 4.56-1.021 8.52-.6 11.64 1.32.42.18.479.659.301 1.02zm1.44-3.3c-.301.42-.841.6-1.262.3-3.239-1.98-8.159-2.58-11.939-1.38-.479.12-1.02-.12-1.14-.6-.12-.48.12-1.021.6-1.141C9.6 9.9 15 10.561 18.72 12.84c.361.181.54.78.241 1.2zm.12-3.36C15.24 8.4 8.82 8.16 5.16 9.301c-.6.179-1.2-.181-1.38-.721-.18-.601.18-1.2.72-1.381 4.26-1.26 11.28-1.02 15.721 1.621.539.3.719 1.02.419 1.56-.299.421-1.02.599-1.559.3z"/>
                    </svg>
                    <span className="text-white font-medium">Have a Listen</span>
                  </span>
                </Link>
              </div>
            </div>
          </div>
          
          {/* Image */}
          <div className="w-[92%] aspect-[16/9] relative mt-4 mb-5 group">
            <Image
              src="/images/giovanni-cordova-studio-mixing-mastering-neve-console-london-uk-2025.jpg"
              alt="Studio background"
              fill
              priority
              sizes="92vw"
              className="object-cover object-center rounded-2xl"
              quality={85}
            />
            {/* Image Credit */}
            <div className="absolute top-2 right-2 transition-opacity duration-200">
              <div className="px-3 h-6 rounded-md bg-[#0A0A0A]/90 text-[10px] font-light flex items-center">
                {/* UK Flag - Using a direct SVG instead of Image component */}
                <div className="w-3 h-3 rounded-full overflow-hidden relative mr-1 flex-shrink-0 border border-white/20 flex items-center justify-center">
                  <svg viewBox="0 0 60 30" className="w-3 h-3 absolute" preserveAspectRatio="xMidYMid slice">
                    <rect width="60" height="30" fill="#012169"/>
                    <path d="M0,0 L60,30 M60,0 L0,30" stroke="#fff" strokeWidth="6"/>
                    <path d="M0,0 L60,30 M60,0 L0,30" stroke="#C8102E" strokeWidth="4"/>
                    <path d="M30,0 v30 M0,15 h60" stroke="#fff" strokeWidth="10"/>
                    <path d="M30,0 v30 M0,15 h60" stroke="#C8102E" strokeWidth="6"/>
                  </svg>
                </div>
                <span className="text-white/50 font-light">
                  <span className="text-white">London</span>, Early <span className="text-white">2024</span>
                </span>
              </div>
            </div>
          </div>
          
          {/* Announcement Banner */}
          <div className="w-full mt-5 mb-4">
            <div className="py-3 px-6">
              <p className="text-center text-white/75 text-xs font-normal leading-relaxed">
                To celebrate the website launch, use the code <strong className="font-bold text-white">2025LAUNCH10</strong> to have a <strong className="font-bold text-white">10%</strong> discount on your next project!
              </p>
            </div>
          </div>
        </section>
        
        {/* Footer */}
        <footer className="w-full py-8 px-6 bg-[#0A0A0A]/90 backdrop-blur-md mt-5">
          <div className="w-full">
            <div className="grid grid-cols-2">
              {/* Connect Section */}
              <div className="flex justify-center">
                <div>
                  <h3 className="text-white font-medium text-sm mb-3 tracking-wide">Connect</h3>
                  <div className="flex flex-col space-y-3">
                    {footerItems
                      .find(section => section.label === 'Connect')
                      ?.items.map((item) => (
                        <Link
                          key={item.label}
                          href={item.href}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-white/60 hover:text-white transition-colors duration-300 text-xs"
                        >
                          {item.label}
                        </Link>
                      ))}
                  </div>
                </div>
              </div>
              
              {/* Information Section */}
              <div className="flex justify-center">
                <div>
                  <h3 className="text-white font-medium text-sm mb-3 tracking-wide">Information</h3>
                  <div className="flex flex-col space-y-3">
                    {footerItems
                      .find(section => section.label === 'Information')
                      ?.items.map((item) => (
                        <Link
                          key={item.label}
                          href={item.href}
                          target={item.href.startsWith('/') ? undefined : "_blank"}
                          rel={item.href.startsWith('/') ? undefined : "noopener noreferrer"}
                          className="text-white/60 hover:text-white transition-colors duration-300 text-xs"
                        >
                          {item.label}
                        </Link>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </main>
  );
} 